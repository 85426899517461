
import Image from 'next/image'


export default function Marques({marques, title, filter, limit, isPage, sousTitre}) {

    if(filter){
        marques = marques.filter((value) => {
            return filter.includes(value.nom)
        })
    }
    if(limit){
        if(!filter){
            marques.sort(() => Math.random() - 0.5);
        }
        marques.length = limit;
    }
    console.log("MARQUES !!",marques)

    return(
        <section id="zone6" className="bg-white py-20 relative z-30">
            <div className={`container ${isPage? 'grid grid-cols-12' : ''} mx-auto px-4 `}>
                
                <h2 className={`text-5xl ${isPage? 'col-span-12 lg:col-start-3 lg:col-span-8' : ''} text-grey mb-6 text-center`} dangerouslySetInnerHTML={{ __html: (title? title : 'Ils nous font <span class="text-color-primary">confiance</span>' )}}></h2>
                {sousTitre && <h3 className="text-4xl md:text-3xl text-xl text-grey mb-6 text-center">{sousTitre}</h3>}

                <div className={`grid grid-cols-12 items-center ${isPage? 'col-span-12 lg:col-start-2 lg:col-span-10' : ''}`}>
                    <div className="col-span-12 md:col-span-10 md:col-start-2">
                        <div className="grid gap-4 grid-cols-12 items-center">
                            {marques.map((marque, index) => (
                                marque? (
                                    isPage ? (
                                        <a className="col-span-6 md:col-span-4 lg:col-span-3" href="/#zone6" key={marque?.nom+"-"+index} id={marque?.nom}>
                                            { !marque?.image?.url ? '' :  (
                                                <Image src={marque?.image?.url+'?w=227&h=142&q=60&fm=webp'} width="227" height="142" alt={marque?.alt}/>
                                            )}
                                        </a>
                                    ) : (
                                        <div className="col-span-6 md:col-span-4 lg:col-span-3" key={marque?.nom+"-"+index} id={marque?.nom}>
                                            { !marque?.image?.url ? '' : (
                                                <Image src={!marque?.image?.url ? '' : marque?.image?.url+'?w=227&h=142&q=60&fm=webp'} width="227" height="142" alt={marque?.alt}/>
                                            )}

                                        </div>
                                    )
                                ): ('')
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}